export const SERVER_IMAGE_STORAGE =
  "https://mechanigoph.sgp1.digitaloceanspaces.com";

export const PaymentStatus = {
  UNPAID: "Unpaid",
  PARTIAL_PAYMENT: "Partially Paid",
  FULLY_PAID: "Fully Paid",
  CANCELLED: "Cancelled",
  FAILED: "Failed",
  PENDING: "Pending",
};

export const paymentStatusTypes = {
  [PaymentStatus.UNPAID]: {
    color: "#FD0000",
    text: PaymentStatus.UNPAID,
  },
  [PaymentStatus.PARTIAL_PAYMENT]: {
    color: "#F79646",
    text: PaymentStatus.PARTIAL_PAYMENT,
  },
  [PaymentStatus.FULLY_PAID]: {
    color: "#05712F",
    text: PaymentStatus.FULLY_PAID,
  },
  [PaymentStatus.CANCELLED]: {
    color: "#ED6140",
    text: PaymentStatus.CANCELLED,
  },
  [PaymentStatus.FAILED]: {
    color: "#ED6140",
    text: PaymentStatus.FAILED,
  },
  [PaymentStatus.PENDING]: {
    color: "#F4AE3D",
    text: PaymentStatus.PENDING,
  },
};

export const PaymentType = {
  reservation_fee: "Reservation Fee",
  downpayment_for_parts: "Downpayment For Parts",
  cancellation_fee: "Cancellation Fee",
};

export const PaymentMethod = {
  paynamics: "Paynamics",
  paypal: "Paypal",
  "Gcash/Paymaya": "Gcash/Paymaya",
  fund_transfer: "Fund Transfer",
  cash_after_service: "Cash After Service",
  "Credit_Card_(via_POS)": "Credit Card (via POS)",
  "Credit_Card_Installment_3mos(via_POS)": "Installment (via POS) 3mos",
  "Credit_Card_Installment_6mos(via_POS)": "Installment (via POS) 6mos",
  purchase_order: "Purchase Order",
  "payment_link_(2c2p)": "Payment Link (2c2p)",
};
