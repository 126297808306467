import React, { Fragment, useEffect, useState, useRef } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { numberConverter } from "../../../utils/accounting";

import AddPaymentForm from "./AddPaymentForm";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  ul: {
    listStyleType: "none",
    padding: "0px",
    marginTop: "3px !important",
  },
  question: {
    marginBottom: "12px !important",
  },
  label: {
    marginRight: "5px",
    fontSize: "15px !important",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "600",
  },
  dataQuestion: {
    fontWeight: "600",
    fontSize: "15px !important",
  },
  data: {
    fontWeight: "600",
    textTransform: "capitalize",
    fontSize: "15px !important",
  },
}));

const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

const AddPaymentDialog = (props) => {
  const classes = useStyles();

  const {
    data,
    open,
    handleAddPaymentClose,
    handleAddPayment,
    isLoading,
    isSubJO = false,
  } = props;

  const formRef = useRef();

  const [payment, setPayment] = useState("");

  const handleChangePayment = (event) => {
    let value = event.target.value;
    value = value.replace(/[^0-9.]/g, "");
    setPayment(value);
  };

  let totalInvoiceAmount =
    parseFloat(data.sub_total) +
    parseFloat(data?.service_fee || 0) -
    parseFloat(data.discount) -
    parseFloat(data.sub_jo_total || 0) +
    parseFloat(data.fees_sum);

  if (isSubJO) {
    totalInvoiceAmount = parseFloat(data.total);
  }
  const totalOutstandingBalance = () => {
    if (data?.payment?.length > 0) {
      const totalPaymentsMade = data.payment
        .filter((payment) => payment.appointment_post_dispatch_id === null)
        .reduce((total, payment) => total + parseFloat(payment.amount_paid), 0);
      return totalInvoiceAmount - totalPaymentsMade;
    }

    return totalInvoiceAmount;
  };

  useEffect(() => {
    if (formRef.current) {
      let form = formRef.current;

      const inputPayment = numberConverter(payment);

      if (isNaN(inputPayment)) {
        form.setFieldValue("amountPaid", 0);
        return;
      } else {
        form.setFieldValue("amountPaid", inputPayment);
      }

      let outstandingBalance =
        parseFloat(data.outstanding_balance) || totalOutstandingBalance();

      const outstandingLessPaymentInput =
        parseFloat(outstandingBalance) - inputPayment;

      if (outstandingLessPaymentInput >= 0) {
        form.setFieldValue("balanceCheck", "verified");
        form.setFieldValue("outstandingBalance", outstandingLessPaymentInput);
      } else {
        form.setFieldValue("balanceCheck", "");
        form.setFieldValue("outstandingBalance", 0);
      }
    }
  }, [data.outstanding_balance, data.total, payment]);

  const handleSubmit = (val) => {
    let merged = { ...val };
    if (isSubJO) {
      merged = { ...val, appointment_post_dispatch_id: data.id };
    }
    handleAddPayment(merged);
  };

  const initialValues = {
    id: data.id,
    invoiceAmount: totalInvoiceAmount.toFixed(2) || 0.0,
    outstandingBalance: data.outstanding_balance
      ? parseFloat(data.outstanding_balance).toFixed(2)
      : totalOutstandingBalance(),
    modeOfPayment: data.payment_method && data.payment_method,
    paymentDate: moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
    paymentRefNo: data.bs_number ? data.bs_number : "",
    amountPaid: 0,
    feeType: data?.appointment_fees?.[0]?.fee_type_id,
    feeAmount: data?.appointment_fees?.[0]?.amount,
    balanceCheck: "verified",
  };

  const validationSchema = Yup.object().shape({
    id: Yup.string().required(),
    invoiceAmount: Yup.string().required(),
    outstandingBalance: Yup.string().required(),
    modeOfPayment: Yup.string().required(),
    paymentDate: Yup.string().required(),
    paymentRefNo: Yup.string().required(),
    balanceCheck: Yup.string().required(),
    amountPaid: Yup.number()
      .positive("Amount Paid should be greater than 0.")
      .required("Amount Paid is required."),
  });

  // useEffect(() => {
  //   setPayment(
  //     data.outstanding_balance
  //       ? parseFloat(data.outstanding_balance)
  //       : totalInvoiceAmount
  //   );
  // }, [open]);

  if (Array.isArray(data) && data.length === 0) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleAddPaymentClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        disableBackdropClick
      >
        <ThemeProvider theme={formLabelsTheme}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formRef}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form>
                {Object.keys(data).length > 0 ? (
                  <Fragment>
                    <DialogTitle
                      id="alert-dialog-title"
                      className={classes.title}
                    >
                      <span
                        className={classes.titleText}
                        style={{ textTransform: "uppercase" }}
                      >
                        Booking NO : {data.reference_no}
                      </span>
                    </DialogTitle>
                    <DialogContent dividers>
                      <AddPaymentForm
                        values={props.values}
                        errors={props.errors}
                        touched={props.touched}
                        setFieldValue={props.setFieldValue}
                        handleChange={props.handleChange}
                        payment={payment}
                        handleChangePayment={handleChangePayment}
                      />
                    </DialogContent>
                  </Fragment>
                ) : (
                  <div></div>
                )}
                {/* <pre>{JSON.stringify({values :props.values, errors: props.errors}, null, 4)}</pre> */}
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={handleAddPaymentClose}
                    color="secondary"
                    startIcon={<CancelIcon />}
                    size="small"
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    autoFocus
                    endIcon={<CheckCircleIcon />}
                    size="small"
                    disabled={isLoading}
                  >
                    CONFIRM{" "}
                    {isLoading && (
                      <CircularProgress
                        size={20}
                        style={{ marginLeft: "3px" }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </ThemeProvider>
      </Dialog>
    </div>
  );
};

export default AddPaymentDialog;
