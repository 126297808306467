import { Fragment, useState, useEffect } from "react";
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { MenuItem, TextField } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import * as Yup from "yup";
import { DatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: "20%" },
}));

const AddAttendance = React.memo((props) => {
  const classes = useStyles();

  const { open, handleUpdateClose, mechanics } = props;

  const [initialValues, setInitialValues] = useState({
    mechanic_id: null,
    date_from: null,
    date_to: null,
    type: "",
    reason: "",
  });

  const handleClose = () => {
    handleUpdateClose();
  };

  // Define validation schema
  const validationSchema = Yup.object({
    mechanic_id: Yup.number().required("Mechanic is required"),
    reason: Yup.string().required("Reason of leave is required"),
    type: Yup.string().required("Leave type is required"),
    date_from: Yup.date()
      .required("Date from is required")
      .nullable()
      .typeError("Invalid date"),
    date_to: Yup.date()
      .required("Date to is required")
      .nullable()
      .typeError("Invalid date"),
  });

  const handleSubmit = (values) => {
    // Handle form submission
    props.handleAddSubmit(values);
  };

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, isSubmitting }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"ADD MECHANIC LEAVE"}
                  </span>
                </DialogTitle>
                <input type="hidden" name="id" />
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FastField
                        fullWidth
                        label="Mechanic"
                        component={(
                          { field, form: { touched, errors } },
                          props
                        ) => (
                          <TextField
                            label="Mechanic"
                            select
                            fullWidth
                            error={errors[field?.name] && touched[field?.name]}
                            {...field}
                            {...props}
                            variant="outlined"
                            size="small"
                            name="mechanic_id"
                          >
                            {mechanics?.map((option) => (
                              <MenuItemStyle key={option.id} value={option.id}>
                                {`${option.firstname} ${option.lastname}`}
                              </MenuItemStyle>
                            ))}
                          </TextField>
                        )}
                        size="small"
                        inputVariant="outlined"
                        name="mechanic_id"
                        variant="outlined"
                        inputProps={{
                          style: { textAlign: "center" },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FastField
                          fullWidth
                          label="Date From"
                          component={DatePicker}
                          size="small"
                          inputVariant="outlined"
                          name="date_from"
                          variant="inline"
                          autoOk
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FastField
                          fullWidth
                          label="Date To"
                          component={DatePicker}
                          size="small"
                          inputVariant="outlined"
                          name="date_to"
                          variant="inline"
                          autoOk
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FastField
                        fullWidth
                        label="Leave type"
                        component={(
                          { field, form: { touched, errors } },
                          props
                        ) => (
                          <TextField
                            label="Leave type"
                            select
                            fullWidth
                            error={errors[field?.name] && touched[field?.name]}
                            {...field}
                            {...props}
                            variant="outlined"
                            size="small"
                            name="type"
                          >
                            {[
                              "Vaction Leave",
                              "Sick Leave",
                              "Parental Leave",
                              "Bereavement leave",
                              "Sabbatical Leave",
                              "Unpaid Leave",
                            ].map((option) => (
                              <MenuItemStyle key={option} value={option}>
                                {`${option}`}
                              </MenuItemStyle>
                            ))}
                          </TextField>
                        )}
                        size="small"
                        inputVariant="outlined"
                        name="type"
                        variant="outlined"
                        inputProps={{
                          style: { textAlign: "center" },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FastField
                        name="reason"
                        component={({
                          field,
                          form: { touched, errors },
                          ...props
                        }) => (
                          <TextField
                            {...field}
                            {...props}
                            error={touched[field.name] && !!errors[field.name]}
                            helperText={
                              touched[field.name] && errors[field.name]
                            }
                            variant="outlined"
                            size="small"
                            multiline
                            label="Reason of leave"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <DialogActions>
                    <Button onClick={handleClose} size="small" color="default">
                      Close
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                      variant="contained"
                      autoFocus
                      size="small"
                      endIcon={
                        isSubmitting ? (
                          <CheckCircleIcon size="0.9rem" />
                        ) : undefined
                      }
                    >
                      {isSubmitting ? "Submitting" : "Submit"}
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});

export default AddAttendance;
