import React, { useState } from "react";
import { withRouter, NavLink, useLocation } from "react-router-dom";
import classes from "../styles/Sidebar.module.css";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import { appSliceAtions } from "../store/app.slice";
import { useSelector, useDispatch } from "react-redux";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SearchIcon from "@material-ui/icons/Search";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DropdownList from "../components/sidebar/DropdownList";
import { SERVER_IMAGE_STORAGE } from "../utils/constant";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    overflowX: "auto",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const itemsList = [
  {
    text: "Search Booking",
    icon: <SearchIcon />,
    path: "/search",
    id: "search",
  },
  {
    text: "Manage Booking",
    icon: <CalendarTodayIcon />,
    path: "/bookings",
    id: "bookings",
  },
];

const Sidebar = (props) => {
  console.log("======SIDEBAR======");

  const { pathname } = useLocation();
  const classes1 = useStyles();
  const theme = useTheme();

  const appStore = useSelector((state) => state.appStore);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    window.location = "/logout";
  };

  return (
    <div className={classes1.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes1.appBar, {
          [classes1.appBarShift]: open,
        })}
      >
        <Toolbar className={classes["brandLogo-color"]}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={`${clsx(classes1.menuButton, open && classes1.hide)}`}
          >
            <MenuIcon style={{ color: "#3f51b5" }} />
          </IconButton>
          <Box style={{ marginRight: "2px" }}>
            <CalendarTodayIcon style={{ color: "#3f51b5" }} />
          </Box>
          <Typography
            variant="h6"
            noWrap
            style={{ color: "#3f51b5", fontWeight: "bold" }}
          >
            {appStore.header_name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes1.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes1.drawerPaper,
        }}
      >
        <div
          className={`${classes1.drawerHeader} ${classes["brandLogo-color"]}`}
        >
          <Box px={3} pb={2}>
            <img
              src={SERVER_IMAGE_STORAGE + "/logo/mechanigo-blue-black-logo.png"}
              className={classes.brandLogo2}
              alt="brand.png"
            />
          </Box>
          <IconButton
            onClick={handleDrawerClose}
            className={classes["slide-button"]}
            title="Close side menu"
          >
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon className={classes["slide-button-logo"]} />
            ) : (
              <ChevronRightIcon className={classes["slide-button-logo"]} />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {itemsList.map((item, index) => {
            return (
              <ListItem
                button
                key={item.id}
                component={NavLink}
                to={item.path}
                activeClassName={classes.activeNav}
                onClick={() => {
                  dispatch(
                    appSliceAtions.changeHeaderName({
                      header_name: item.text,
                    })
                  );
                }}
              >
                {item.icon && (
                  <ListItemIcon
                    className={
                      pathname === item.path ? classes.activeNavIcon : ""
                    }
                  >
                    {item.icon}
                  </ListItemIcon>
                )}
                <ListItemText primary={item.text} />
              </ListItem>
            );
          })}
        </List>
        <Divider />

        <DropdownList />

        <Box style={{ padding: "10px 5%" }}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            fullWidth
            onClick={handleLogout}
          >
            LOGOUT
          </Button>
        </Box>
      </Drawer>
      <main
        className={clsx(classes1.content, {
          [classes1.contentShift]: open,
        })}
      >
        <div className={classes1.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
};

export default withRouter(Sidebar);
