import React, { useState } from "react";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  FormControl,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
}));

const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

const FeeType = (props) => {
  const classes = useStyles();

  const { open, handleAddFeeClose, handleSubmitAddFee, isLoading } = props;

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Fee Type is required"),
  });

  const handleSubmit = (values) => {
    handleSubmitAddFee(values);
  };

  return (
    <Dialog
      open={open}
      onClose={handleAddFeeClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <ThemeProvider theme={formLabelsTheme}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <DialogTitle id="alert-dialog-title" className={classes.title}>
                <div
                  className={classes.titleText}
                  style={{ textTransform: "uppercase" }}
                >
                  Add Fee Type
                </div>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Field
                        name="name"
                        label="Fee Type"
                        component={TextField}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={handleAddFeeClose}
                  color="secondary"
                  startIcon={<CancelIcon />}
                  size="small"
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  autoFocus
                  endIcon={<CheckCircleIcon />}
                  size="small"
                  disabled={isLoading}
                >
                  CONFIRM{" "}
                  {isLoading && (
                    <CircularProgress size={20} style={{ marginLeft: "3px" }} />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </ThemeProvider>
    </Dialog>
  );
};

export default FeeType;
